import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetActiveAccount } from 'common/store/accounts/slice'
import { createAccount } from 'common/store/accounts/actions';
import AddNewPartner from './AddNewPartner';
import { RootState } from 'common/store/rootReducer';
import { appSelectors } from 'common/store/app/slice';

const Partners: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestError = useSelector((state: RootState) => state.accounts.error);
  const accountId = useSelector((state: RootState) => state.accounts.currentAccountId);
  const currencyList = useSelector((state: RootState) => appSelectors.getCurrencies(state));

  const handleCreateAccount = async (values: object): Promise<{ id: number } | null> => {
    try {
      const response = await dispatch(createAccount(values) as any);
      return response; 
    } catch (error) {
      console.error("Error creating account:", error);
      return null;
    }
  };

  const handleResetActiveAccount = () => dispatch(resetActiveAccount());

  return (
    <AddNewPartner
      createAccount={handleCreateAccount}
      resetActiveAccount={handleResetActiveAccount}
      requestError={requestError}
      accountId={accountId}
      currencyList={currencyList}
      navigate={navigate}
    />
  );
};

export default Partners;
