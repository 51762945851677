import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { Steps, Row, Col, Form } from 'antd'
import { Button } from 'common/components/Button'
import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import ViewContainer from 'common/components/ViewContainer'
import FormContainer from 'common/components/FormContainer'
import CompanyInformationForm from '../CompanyInformationForm'
import UserInformationForm from './UserInformationForm'
import ConfigureForm from '../ConfigureForm'
import AccessKeys from '../AccessKeys'
import { castToNumber, castToPercentage } from 'common/utils/common'
import { FormValues, addPartnerValidations } from '../validationsSchema'
import { StepItem } from './styledComponents'
import { fetchAccountById } from 'common/store/accounts/actions'
import { useDispatch } from 'react-redux'

const stepOne = {
  addressOne: '',
  addressTwo: '',
  city: '',
  country: 'CA',
  organizationName: '',
  province: '',
  postalCode: '',
  website: '',
  cryptoCompany: false,
  enableRewardsPage: false,
  reconcilePartner: false
}

const stepTwo = {
  firstName: '',
  lastName: '',
  partnerEmail: ''
}

const stepThree = {
  prefundedAmount: undefined,
  prefundCurrency: 'CAD',
  prefundedAmountWarningTopupAmount: undefined,
  topupWarningEmails: '',
  partnersCommission:0,
  maxDiscount:0,
  fxSpreadPercent: 0,
  topUpFxSpreadPercent: 0,
  marginOffset: 0,
  transactionFeeType: '',
  transactionFee: 0,
  prefundedAmountWarningLow: 0,
  breakageSplitPercent: 0,
  transactionExpiryInMonth: 0,
  supportEmail: '',
}

interface AddNewPartnerProps {
  createAccount: (values: object) => Promise<{ id: number } | null>
  resetActiveAccount: () => void
  requestError: string | null
  accountId: number | null
  currencyList: string[]
  navigate: ReturnType<typeof useNavigate>
}

const AddNewPartner: React.FC<AddNewPartnerProps> = ({
  createAccount,
  resetActiveAccount,
  requestError,
  accountId,
  currencyList,
  navigate,
}) => {
  const [current, setCurrent] = useState(0);
  const [partnerId, setPartnerId] = useState<number | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (accountId && !requestError) {
      setPartnerId(accountId)
      setCurrent(3)
    }
  }, [requestError, accountId])

  useEffect(() => {
    // Use the cleanup function only on unmount
    return () => {
      resetActiveAccount();
    };
  }, [resetActiveAccount]);

  const steps = [
    {
      title: 'Company Information',
      content: <CompanyInformationForm />,
      fields: stepOne
    },
    {
      title: 'User Information',
      content: <UserInformationForm />,
      fields: stepTwo
    },
    {
      title: 'Configure',
      content: <ConfigureForm />,
      fields: stepThree
    },
    {
      title: 'Access Key',
      content: <AccessKeys id={partnerId} />,
      fields: {}
    }
  ]

  const initialValues = {
    ...stepOne,
    ...stepTwo,
    ...stepThree
  }

  const getCurrentErrors = (allErrors: Object) => {
    return Object.keys(allErrors).filter((item) =>
      steps[current].fields.hasOwnProperty(item)
    )
  }

  const hasErrors = (allErrors: Object) => {
    return getCurrentErrors(allErrors).length > 0
  }

  const onSubmit = async  (values: FormValues) => {
    const updatedValues = {
      ...values,
      prefundedAmount: castToNumber(values.prefundedAmount),
      prefundedAmountWarningTopupAmount: castToNumber(
        values.prefundedAmountWarningTopupAmount
      ),
      partnersCommission: castToPercentage(values.partnersCommission),
      maxDiscount: castToNumber(values.maxDiscount),
      fxSpreadPercent: castToPercentage(values.fxSpreadPercent),
      topUpFxSpreadPercent: castToPercentage(values.topUpFxSpreadPercent),
      marginOffset: castToNumber(values.marginOffset),
      transactionFeeType: values.transactionFeeType,
      transactionFee: castToNumber(values.transactionFee),
      prefundedAmountWarningLow: castToNumber(values.prefundedAmountWarningLow),
      breakageSplitPercent: castToPercentage(values.breakageSplitPercent),
      transactionExpiryInMonth: castToNumber(values.transactionExpiryInMonth),
      topupWarningEmails: values.topupWarningEmails,
      supportEmail: values.supportEmail
    }

    try {
    const response = await createAccount(updatedValues);
    
    if (response && response.id !== undefined) {
      setPartnerId(response.id);
      await dispatch(fetchAccountById(response.id) as any);
      setTimeout(() => {
        setCurrent(3);
      }, 1000);
    } else {
      console.error("Account creation failed, no valid response received.");
    }
  } catch (error) {
    console.error("Error creating account:", error);
  }
  }

  return (
    <ViewContainer title='Add New Partner' subPath='partners'>
      <Row justify='space-between'>
        <Col span={16}>
          <Formik
            initialValues={initialValues}
            validationSchema={addPartnerValidations}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, errors, touched }) => (
              <>
                <FormContainer title={steps[current].title}>
                  <Form id='createAccount' onFinish={handleSubmit}>
                    {steps[current].content}
                  </Form>
                </FormContainer>
                <Row justify='end'>
                  {current > 0 && current < steps.length - 1 && (
                    <Button
                      type='ghost'
                      shape='round'
                      style={{ marginRight: 20 }} 
                      onClick={() => setCurrent(current - 1)}
                    >
                      <LeftOutlined />
                      Previous
                    </Button>
                  )}

                  {current < steps.length - 2 && (
                    <Button
                      shape='round'
                      type='primary'
                      onClick={() => setCurrent(current + 1)}
                      disabled={
                        hasErrors(errors) || Object.keys(touched).length === 0
                      }
                    >
                      Next
                      <RightOutlined />
                    </Button>
                  )}
                  {current === steps.length - 2 && (
                    <Button
                      htmlType='submit'
                      form='createAccount'
                      shape='round'
                      type='primary'
                      disabled={Object.keys(errors).length > 0}
                    >
                      Create Account
                      <CheckOutlined />
                    </Button>
                  )}

                  {current === steps.length - 1 && (
                    <Button
                      shape='round'
                      type='primary'
                      onClick={() => navigate('/partners')}
                    >
                      Finish
                      <CheckOutlined />
                    </Button>
                  )}
                </Row>
              </>
            )}
          </Formik>
        </Col>
        <Col span={6}>
          <Steps current={current} direction='vertical' size='small'>
            {steps.map(item => (
              <StepItem key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
      </Row>
    </ViewContainer>
  )
}

export default AddNewPartner
