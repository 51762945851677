import React, {useEffect} from 'react'
import { Button, Row, Switch, Modal } from 'antd'
import { Input } from 'common/components/Input'
import { StyledInput, Label } from './styledComponents'
import CopyToClipboard from 'common/components/CopyToClipBoard'
import { Mode, setApiKey, setSecret, setAccountDisable } from 'common/store/accounts/actions'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'common/store/rootReducer'
import { accountSelectors } from 'common/store/accounts/slice'
import { fetchAccountById } from 'common/store/accounts/actions'

const { confirm } = Modal

interface AccessKeysProps {
  id: number | null;
}

const AccessKeys: React.FC<AccessKeysProps> = ({ id }) => {
  const dispatch = useDispatch()

  console.log(`AccessKeys Component Mounted - ID: ${id}`);
  
  // Fetch the latest account details when ID changes
  useEffect(() => {
    if (id) {
      dispatch(fetchAccountById(id) as any);
    }
  }, [dispatch, id]);

  // Get Keys from Redux
  const { disabled, organizationName, apiKey, requestSecret } = useSelector((state: RootState) => {
    return accountSelectors.getCurrentAccount(state) || {
      disabled: true,
      organizationName: '',
      apiKey: '',
      requestSecret: ''
    };
  });

  // If API Key is missing, re-fetch account details
  useEffect(() => {
    if (id && !apiKey) {
      dispatch(fetchAccountById(id) as any);
    }
  }, [dispatch, id, apiKey]);


  const showConfirmationRevoke = (id: number, name: string, setKey: (id: number, mode: Mode) => void) => {
    confirm({
      title: `Are you sure you want to revoke  ${name}?`,
      content: '',
      okText: 'Yes',
      onOk: async () => {
        if (id) {
          await dispatch(setKey(id, Mode.Revoke) as any);
          setTimeout(() => dispatch(fetchAccountById(id) as any), 1000);
        }
      }
    })
  }

  const getInputAddon = (
    key: any,
    setKey: (id: number, mode: Mode) => void,
    name: string
  ) => {
    return key ? (
      <Button
        style={{ width: 175 }}
        danger
        onClick={id !== null ? () => showConfirmationRevoke(id,name, setKey) : undefined}
        
      >
        Revoke {name}
        <CloseOutlined />
      </Button>
    ) : (
      <Button
        style={{ width: 175 }}
        onClick={async () => {
          if (id) {
            await dispatch(setKey(id, Mode.Generate) as any);
            setTimeout(() => dispatch(fetchAccountById(id) as any), 1500);
          }
        }}
      >
        Generate {name}
        <PlusOutlined />
      </Button>
    )
  }

  const showConfirmation = (id: number, disable: boolean) => {
    let content = disable
      ? `Are you sure you would like to temporarily disable ${organizationName}? This would mean all requests from the given partner will be denied.`
      : `Are you sure you would like to enable ${organizationName}? This would mean all requests from the given partner will be granted.`;

    confirm({
      title: '',
      content: content,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(setAccountDisable(id, disable ? Mode.DisableAccount : Mode.EnableAccount) as any);
        }
    })
  }

  const secretAddon = getInputAddon(requestSecret, (id: number, mode: Mode) => dispatch(setSecret(id, mode)), 'Secret')
  const apiAddon = getInputAddon(apiKey, (id: number, mode: Mode) => dispatch(setApiKey(id, mode)), 'API Key')

  return (
    <>
      <Row align='middle' style={{ marginBottom : '20px' }}>
        <Input.Group style={{ width: '30%', height : '20px' }}>
          <Label>Temporarily Disable Account Access  </Label>
        </Input.Group>
        <Switch checked={disabled} onChange={(checked) => id && showConfirmation(id, checked)} />
      </Row>
      {/* API Key Section */}
      <Row align='middle'>
        <Input.Group style={{ width: '80%' }}>
          <Label>API Key</Label>
          <StyledInput
            disabled
            value={apiKey || ''}
            addonAfter={getInputAddon(apiKey, setApiKey, 'API Key')}
            placeholder='API Key'
          />
        </Input.Group>
        {apiKey && (
          <CopyToClipboard
            text={apiKey}
            successMessage='Copied API Key to clipboard'
          />
        )}
      </Row>
      {/* Secret Key Section */}
      <Row align='middle'>
        <Input.Group style={{ width: '80%' }}>
          <Label>Secret</Label>
          <StyledInput
            disabled
            value={requestSecret || ''}
            placeholder='Secret'
            addonAfter={getInputAddon(requestSecret, setSecret, 'Secret')}
          />
        </Input.Group>
        {requestSecret && (
          <CopyToClipboard
            text={requestSecret}
            successMessage='Copied Secret to clipboard'
          />
        )}
      </Row>
    </>
  )
}
export default AccessKeys
