import React from 'react'
import { Menu, Icon, StyledLink } from './styledComponents'
import { RoutePaths } from 'core/Router/routePaths'
import { HomeOutlined, SwapOutlined } from '@ant-design/icons'

const PartnerNavigation: React.FC = () => {
  return (
    <Menu mode='inline'>
      <Menu.Item key='home'>
        <StyledLink to={RoutePaths.DASHBOARD}>
          <HomeOutlined /><span>Dashboard</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Transactions'>
        <StyledLink to={RoutePaths.TRANSACTIONS}>
          <SwapOutlined /> <span>Transactions</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Settings'>
        <StyledLink to={RoutePaths.SETTINGS}>
          <Icon type='AuditOutlined' /> <span>Settings</span>
        </StyledLink>
      </Menu.Item>
    </Menu>
  )
}

export default PartnerNavigation
